<template>
  <div id="wishlist">
    <v-flex xs12>
      <h1 v-if="!isMobile">{{ $t('wishlist.title')}}</h1>
      <p v-if="!isMobile" class="text">{{ $t('wishlist.subtitle')}}</p>

      <v-flex xs12 sm12 md12 v-if="wishlist.length === 0">
        <p class="text"> {{ $t('wishlist.no-data')}} </p>
      </v-flex>

      <v-layout wrap>
        <v-flex :class="isMobile ? 'mb-2 xs12' : 'mt-4 xs12 sm12 md6'" v-for="(item, index) in wishlist" :key="index">
          <v-divider v-if="!isMobile" class="mt-4 mb-4"></v-divider>
          <v-layout wrap class="item bordered" v-if="isMobile">
            <v-flex xs4>
              <img v-lazyload v-bind:src="item.url_image" width="100%" :alt="item.url_image" @click="toProduct(item)">
            </v-flex>
            <v-flex xs7 offset-xs1>
              <div class="detail-item">
                <span class="title Uppercase"> {{ item.product_name }} </span>
                <p class="italic"> {{ item.product_code }} </p>
                <p class="subtitle-gray" v-if="item.harga_coret && item.harga_coret != 0"><b>{{ addComma(item.harga_coret) }} IDR</b></p>
                <p class="subtitle"><b>{{ addComma(item.selling_price) }} IDR</b></p>
              </div>
            </v-flex>
            <v-flex xs12>
              <v-btn
                class="mt-3"
                tile
                color="black"
                dark
                width="100%"
                height="44px"
                elevation="1"
                @click="deleteWishlist(item.id_wishlist)"
              >
                <span class="btn-text uppercase"> {{ $t('wishlist.delete')}} </span>
              </v-btn>
            </v-flex>
          </v-layout>
          <v-layout wrap :class="(index%2 === 0) ? 'item right-bordered' : 'item'" v-else>
            <v-flex xs8>
              <div class="detail-item">
                <span class="title Uppercase"> {{ item.product_name }} </span>
                <p class="italic"> {{ item.product_code }} </p>
                <p class="subtitle-gray" v-if="item.harga_coret && item.harga_coret != 0"><b>{{ addComma(item.harga_coret) }} IDR</b></p>
                <p class="subtitle"><b>{{ addComma(item.selling_price) }} IDR</b></p>
              </div>
            </v-flex>
            <v-flex xs4>
              <img v-lazyload v-bind:src="item.url_image" width="100%" :alt="item.url_image" @click="toProduct(item)">
            </v-flex>
            <v-flex xs4 offset-xs8>
              <v-btn
                tile
                color="black"
                dark
                width="100%"
                height="44px"
                elevation="1"
                @click="deleteWishlist(item.id_wishlist)"
              >
                <span class="btn-text uppercase"> {{ $t('wishlist.delete')}} </span>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

    </v-flex>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/axios'

  export default {
    name: 'Order',
    computed: {
      ...mapState([
        'isMobile',
        'isLogin',
        'token'
      ])
    },
    data () {
      return {
        wishlist: [],
        dialog: false
      }
    },
    methods: {
      close () {
        this.dialog = false
      },
      getWishlist () {
        this.$store.commit('setIsLoading', true)

        axios.defaults.headers = {
          id_brand: process.env.VUE_APP_BRAND,
          token: this.token
        }

        axios.get('/api/v1/member/wishlist')
          .then(response => {
            this.wishlist = response.data.data
          })
          .catch(error => {
            this.errorHandling(error)
          })
          .finally(() => {
            this.$store.commit('setIsLoading', false)
          })
      },
      deleteWishlist (id) {
        this.$store.commit('setIsLoading', true)

        axios.defaults.headers = {
          id_brand: process.env.VUE_APP_BRAND,
          token: this.token
        }

        axios.delete('/api/v1/member/wishlist/'+ id)
          .then(response => {
            this.$store.commit('setSnackbar', true)
            this.$store.commit('setText', response.data.message)

            this.getWishlist()
          })
          .catch(error => {
            this.errorHandling(error)
          })
          .finally(() => {
            this.$store.commit('setIsLoading', false)
          })
      }
    },
    // beforeRouteLeave: function(to, from, next) {
    //   this.$store.commit('setCanBack', true)
    //   next();
    // },
    mounted () {
      if (this.isLogin) {
        this.getWishlist()
      }
      this.setMetaInfo(null, 'Wishlist')
      this.$store.commit('setIsHome', false)
      this.$store.commit('setIsLoginPage', false)
      this.$store.commit('setIsMemberPage', true)
      this.$store.commit('setActivePage', this.$t('wishlist.title'))
    }
  }
</script>

<style scoped>
  #wishlist {
    margin-bottom: 50px;
  }

  .item {
    padding: 10px;
  }

  .detail-item {
    color: #000000;
  }

  .text {
    color: #000000;
    margin-bottom: 5px;
    font-size: 0.9rem !important;
    font-style: normal !important;
  }

  p {
    margin-bottom: 5px;
  }
  .title {
    color: #000000;
    font-weight: 900;
    font-size: 0.9rem !important;
  }

  .subtitle {
    color: #000000;
    font-size: 0.8rem !important;
  }

  .subtitle-gray {
    color: #808080;
    margin-bottom: 0;
    font-size: 0.8rem !important;
    text-decoration: line-through;
  }

  .img {
    position: relative;
    top: 20px;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .italic {
    font-style: italic;
    font-weight: normal;
  }

  .bordered {
    border: 1px solid #C0C0C0;
  }

  .right-bordered {
    border-right: 2px solid #C0C0C0;
  }

  .btn-text {
    font-size: 0.6rem;
    font-weight: bold;
  }

  .uppercase {
    text-transform: uppercase;
  }

</style>
